import React, { SFC } from 'react'
import { WindowLocation } from '@reach/router'

import Layout from '../components/layout'
import Section, {Header, SubHeader, Divider} from '../components/section/section'
import ContactInfo from '../components/contactInfo'
import theme from '../theme'

type Props = {
  location: WindowLocation
}

const Rates: SFC<Props> = ({ location }) => (
  <Layout location={location}>
    <Section width="100%" margin="0" padding="40px 0" flexDirection="column" background="whitesmoke">
      <Header title="Rates"/>
      <SubHeader>
        The Venue of Hollywood offers a variety of customizable events and we would love to work with you on a tailored quote to fit your exact needs.
      </SubHeader>
      <Divider themeColor={theme.palette.primary.main}/>
      <ContactInfo />
    </Section>
  </Layout>
)

export default Rates