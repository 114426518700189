import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import Theme from '../theme'
import Section from './section/section'

const ContactLink = styled.a`
    font-weight: 100;
    color: ${props => props.color ? props.color : Theme.palette.primary.light};
    text-decoration: none;
    display: block;
    width: inherit;

    :hover {
        color: ${Theme.palette.primary.dark};
        text-decoration: none;
    }
`

const Icon = styled.i`
    font-size: 36px; 
    margin-right: 20px;
`

const SectionBlock = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 20px;
    align-items: center;
`

const AddressLineWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: left;
`

const AddressLine = styled.p`
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
`

const ContactInfo = (props: CSSProperties) => {
    return(
        <Section 
            flexDirection="column" 
            alignItems="start" 
            margin={props.margin ? props.margin : '0 auto 40px auto'}
            padding="0">
            <ContactLink 
                href="mailto:info@thevenueofhollywood.com"
                color={props.color}>
                <SectionBlock>
                    <Icon className="material-icons">email</Icon>
                    info@thevenueofhollywood.com
                </SectionBlock>
            </ContactLink>
            <ContactLink 
                href="tel:1-323-498-5255"
                color={props.color}>
                <SectionBlock>
                    <Icon className="material-icons">phone</Icon>
                    (323) 498-5255
                </SectionBlock>
            </ContactLink>
            <ContactLink 
                href="https://goo.gl/maps/ssjtxkMuv692" 
                target="_blank"
                color={props.color}>
                <SectionBlock>
                    <Icon className="material-icons">directions</Icon>
                    <AddressLineWrapper>
                        <AddressLine>6801 Hollywood Blvd, Ste 200</AddressLine>
                        <AddressLine>Hollywood, CA 90028 United States</AddressLine>
                    </AddressLineWrapper>
                </SectionBlock>
            </ContactLink>
        </Section>
    )
}

export default ContactInfo